export const previousCurrent = [
  { key: 'previousCurrentOD', label: 'OD' },
  { key: 'previousCurrentODVA', label: 'VA' },
  { key: 'previousCurrentOS', label: 'OS' },
  { key: 'previousCurrentOSVA', label: 'VA' },
  { key: 'previousCurrentPD', label: 'PD' },
];

export const unaidedVision = [
  { key: 'unaidedVisionOD', label: 'OD' },
  { key: 'unaidedVisionOS', label: 'OS' },
  { key: 'unaidedVisionOU', label: 'OU' },
];

export const subjectiveRefraction = [
  { key: 'subjectiveRefractionOD', label: 'OD' },
  { key: 'subjectiveRefractionODVA', label: 'VA' },
  { key: 'subjectiveRefractionOS', label: 'OS' },
  { key: 'subjectiveRefractionOSVA', label: 'VA' },
];

export const keratometry = [
  { side: 'left', label: 'OD', fields: [{ key: 'keratometryLeftR1', label: 'R1' }, { key: 'keratometryLeftR2', label: 'R2' }] },
  { side: 'right', label: 'OS', fields: [{ key: 'keratometryRightR1', label: 'R1' }, { key: 'keratometryRightR2', label: 'R2' }] },
];

export const pupilReflex = [
  { key: 'OD', fields: [{ key: 'pupilReflexLeftDirect', label: 'Direct' }, { key: 'pupilReflexLeftConsensual', label: 'Consensual' }, { key: 'pupilReflexLeftNear', label: 'Near' }] },
  { key: 'OS', fields: [{ key: 'pupilReflexRightDirect', label: 'Direct' }, { key: 'pupilReflexRightConsensual', label: 'Consensual' }, { key: 'pupilReflexRightNear', label: 'Near' }] },
];

export const anteriorSegment = [
  { key: 'anteriorSegmentLids', label: 'Lids/Lashes' }, { key: 'anteriorSegmentCornea', label: 'Cornea' }, { key: 'anteriorSegmentConjunctiva', label: 'Conjunctiva' }, { key: 'anteriorSegmentAC', label: 'Anterior Chamber' }, { key: 'anteriorSegmentIris', label: 'Iris' }
];

export const ophthalmoscopy = [
  {key: 'ophthalmoscopyMedia', label: 'Media'},
  {key: 'ophthalmoscopyDiscCupping', label: 'Disc/Cupping'},
  {key: 'ophthalmoscopyCDRatio', label: 'CD ratio'},
  {key: 'ophthalmoscopyAV', label: 'AV'},
  {key: 'ophthalmoscopyMacula', label: 'Macula'},
];

